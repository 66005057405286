import { useSessionData } from '@custom_hooks/use_session_data';
import { SegmentService } from '@services/segment_service';
// import { HeartOutline } from '@shared_components/icons/heart_outline';
import { User } from '@shared_components/icons/user';
import { SocialIcons } from '@shared_components/social_icons';
import classNamesBind from 'classnames/bind';
// import Link from 'next/link';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { loginMessage } from './login_message';
import { loginUrl } from './login_url';
import { MobileItemTypes } from './mobile_item_types/mobile_item_types';
import { MobileMenuAccordion } from './mobile_menu_accordion';
import styles from './nav-mobile-menu.module.css';
// import { RecentArticles } from './recent_articles/recent_articles';
// import { TheCompanyLinks } from './the_company_links';
// import { useBrand } from '@utility_functions/getBrandProps';

const classNames = classNamesBind.bind(styles);

export const MobileMenu: FunctionComponent<{
  open: boolean;
  onClose: () => void;
  locationModalButton: React.ReactNode;
}> = ({ open, onClose, locationModalButton }) => {
  const { data: sessionData } = useSessionData();
  const currentUser = sessionData?.currentUser;
  function handleBackgroundClick() {
    SegmentService.trackEngagementEvent('Mobile Menu Background Clicked', {});
    onClose();
  }
  // const { renderByBrand } = useBrand();

  /**
   * renderMobileMenu is here to increase the lighthouse performance score
   * One of the opportunities for increased performance is to reduce the # of dom elements
   * https://web.dev/dom-size/?utm_source=lighthouse&utm_medium=lr
   * Only rendering this menu if the user needs it by clicking on it. Google doesn't need it when crawling the page.
   * No need to set this to false because we don't need to remove it from the dom once it starts being used.
   */
  const [renderMobileMenu, setRenderMobileMenu] = useState(false);
  useEffect(() => {
    if (open) {
      setRenderMobileMenu(true);
    }
  }, [open]);

  return (
    <>
      <div
        id="mobileMenu"
        className={classNames({
          'fern-navbar--mobile-menu': true,
          opened: open,
        })}
      >
        {renderMobileMenu && (
          <>
            <div className={styles['fern-mobile-menu--container']}>
              {/* desktop left menu items */}
              <div className="mb-30">
                <MobileMenuAccordion
                  id="mobileMenuTheGoods"
                  title="The Goods"
                  segmentEventContext="NavBarMobile TheGoods"
                >
                  <MobileItemTypes></MobileItemTypes>
                </MobileMenuAccordion>
              </div>
              {/* {renderByBrand({
                fernish: (
                  <>
                    <div className="mb-30">
                      <div
                        className={[
                          styles['fern-mobile-menu--item-label'],
                          'fern-xxlarge-body',
                        ].join(' ')}
                      >
                        <Link href="/n/service">The Service</Link>
                      </div>
                    </div>
                    <div className="mb-30">
                      <MobileMenuAccordion
                        id="mobileMenuTheCompany"
                        title="The Company"
                        segmentEventContext="NavBarMobile TheCompany"
                      >
                        <TheCompanyLinks></TheCompanyLinks>
                        <RecentArticles></RecentArticles>
                      </MobileMenuAccordion>
                    </div>
                  </>
                ),
                feather: (
                  <>
                    <div className="mb-30">
                      <div
                        className={[
                          styles['fern-mobile-menu--item-label'],
                          'fern-xxlarge-body',
                        ].join(' ')}
                      >
                        <Link href="/n/faq">FAQ</Link>
                      </div>
                    </div>
                    <div className="mb-30">
                      <div
                        className={[
                          styles['fern-mobile-menu--item-label'],
                          'fern-xxlarge-body',
                        ].join(' ')}
                      >
                        <Link
                          href="https://explore.livefeather.com/"
                          target="_blank"
                        >
                          Feather for Business
                        </Link>
                      </div>
                    </div>
                  </>
                ),
              })} */}
            </div>
            <hr className={styles['fern-mobile-menu--hr']} />
            <div className={styles['fern-mobile-menu--container']}>
              {/* desktop right menu items */}
              <div className="mb-30 text-center">{locationModalButton}</div>
              <div className="mb-30 text-center">
                <a
                  className="flex items-center"
                  href={loginUrl(!!currentUser)}
                  onClick={() =>
                    SegmentService.trackEngagementEvent(
                      'Mobile NavBar UserProfileLinkClicked',
                      {}
                    )
                  }
                >
                  <User style={{ height: 20, width: 20 }} />
                  <span className="ml-20 small-desktop:ml-10 text-[20px]">
                    {loginMessage(currentUser)}
                  </span>
                </a>
              </div>
              {/* <div className="mb-30">
                <a
                  className="flex items-center"
                  href="/wishlists"
                  onClick={() =>
                    SegmentService.trackEngagementEvent(
                      'Navbar Wishlist Mobile Link Clicked',
                      {}
                    )
                  }
                >
                  <HeartOutline style={{ height: 20, width: 20 }} />
                  <span className="ml-20 small-desktop:ml-10 text-[20px]">
                    Wishlists
                  </span>
                </a>
              </div> */}
            </div>
            <hr className={styles['fern-mobile-menu--hr']} />
            <div className={styles['fern-mobile-menu--container']}>
              {/* social */}
              <div className={styles['fern-mobile-menu--social-icons']}>
                <SocialIcons></SocialIcons>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className={classNames({
          'fern-navbar--mobile-menu-overlay': true,
          opened: open,
        })}
        onClick={handleBackgroundClick}
      ></div>
    </>
  );
};
