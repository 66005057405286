import { useMouseDraggableEffect } from '@custom_hooks/use_mouse_draggable_effect';
import { SegmentService } from '@services/segment_service';
import { throttle } from 'lodash-es';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { SidePaginationButton } from '../pagination_buttons/side_pagination_button';
import styles from './paginated_carousel.module.css';

export type DesktopSlidesPerPage = 3 | 4 | 6 | undefined;

export const PaginatedCarousel: FunctionComponent<{
  centerNavToSquareTileImages?: boolean;
  segmentEventContext: string;
  segmentData?: any;
  desktopSlidesPerPage?: DesktopSlidesPerPage;
  ariaLabel: string;
  className?: string;
}> = ({
  children,
  centerNavToSquareTileImages = false,
  segmentEventContext,
  segmentData,
  desktopSlidesPerPage = 4,
  ariaLabel,
  className = '',
}) => {
  // state
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [transformValue, setTransformValue] = useState('');
  const [navContainerHeight, setNavContainerHeight] = useState('100%');
  const [wrapperNode, setWrapperNode] = useState<HTMLElement | null>(null);

  // constants
  // note - pagination only applies to desktop
  const numberOfSlides = React.Children.count(children);
  const minSlideIndex = 0;
  const maxSlideIndex = numberOfSlides - desktopSlidesPerPage;
  const carouselId = ariaLabel.replace(/\s/g, '-');

  // ------------------------------------
  // Effects
  // ------------------------------------
  const wrapperRef = useCallback<any>(
    (node: HTMLElement) => setWrapperNode(node),
    []
  );

  const carouselRef = useMouseDraggableEffect(true);

  useEffect(() => {
    function resetCarousel() {
      setTransformValue('');
      setCurrentSlideIndex(0);
      if (carouselRef.current) {
        const element = carouselRef.current;

        try {
          // we need to scroll to 0, 0 in case we drag/scroll in mobile and then change window to desktop
          // otherwise the wrapper that holds/places the arrow buttons will not be in the correct position.
          element.scroll(0, 0);
        } catch (e) {}

        setNavContainerHeight(
          calculateNavContainerHeight({
            element,
            desktopSlidesPerPage,
            centerNavToSquareTileImages,
          })
        );
      }
    }
    const throttledReset = throttle(resetCarousel, 500);
    window.addEventListener('resize', throttledReset);
    return () => {
      window.removeEventListener('resize', throttledReset);
    };
  }, []);

  // ------------------------------------
  // Helper functions
  // ------------------------------------
  function moveLeft() {
    moveToSlide(currentSlideIndex - desktopSlidesPerPage);
  }

  function moveRight() {
    moveToSlide(currentSlideIndex + desktopSlidesPerPage);
  }

  function handleLeftClick() {
    moveLeft();
  }

  function handleRightClick() {
    moveRight();
  }

  function moveToSlide(newSlideIndex: number) {
    if (!wrapperNode) {
      return;
    }

    const slideOffset = wrapperNode.scrollWidth / numberOfSlides;

    let slideIndex;
    if (newSlideIndex > maxSlideIndex) {
      slideIndex = maxSlideIndex;
    } else if (newSlideIndex < minSlideIndex) {
      slideIndex = minSlideIndex;
    } else {
      slideIndex = newSlideIndex;
    }

    setTransformValue(`translateX(calc(-${slideOffset}px * ${slideIndex}))`);
    setCurrentSlideIndex(slideIndex);
  }

  function handleArrowKeys(e: React.KeyboardEvent) {
    switch (e.key) {
      case 'ArrowLeft':
        SegmentService.trackEngagementEvent(
          `${segmentEventContext} Carousel Left Arrow Keyed`,
          { ...segmentData }
        );
        moveLeft();
        break;
      case 'ArrowRight':
        SegmentService.trackEngagementEvent(
          `${segmentEventContext} Carousel Right Arrow Keyed`,
          { ...segmentData }
        );
        moveRight();
        break;
      default:
        break;
    }
  }

  // ------------------------------------
  // Render helpers
  // ------------------------------------
  function getDesktopPageClass(): string {
    switch (desktopSlidesPerPage) {
      case 4:
        return styles['four-across'];
      case 3:
        return styles['three-across'];
      case 6:
        return styles['six-across'];
      default:
        return '';
    }
  }

  function showPaginationArrows(): boolean {
    return numberOfSlides > desktopSlidesPerPage;
  }

  function isSlideVisible(index: number): boolean {
    const minIndex = currentSlideIndex;
    const maxIndex = currentSlideIndex + desktopSlidesPerPage - 1;

    return index >= minIndex && index <= maxIndex;
  }

  const leftButton = (
    <div
      className={styles['fern-paginated-carousel--nav-container']}
      style={{ height: navContainerHeight }}
    >
      <SidePaginationButton
        direction="Left"
        onClick={handleLeftClick}
        disabled={currentSlideIndex <= minSlideIndex}
        aria-controls={carouselId}
        segmentEventContext={`${segmentEventContext} Carousel`}
      ></SidePaginationButton>
      <div></div>
    </div>
  );

  const rightButton = (
    <div
      className={styles['fern-paginated-carousel--nav-container']}
      style={{ height: navContainerHeight }}
    >
      <div></div>
      <SidePaginationButton
        direction="Right"
        onClick={handleRightClick}
        disabled={currentSlideIndex >= maxSlideIndex}
        aria-controls={carouselId}
        segmentEventContext={`${segmentEventContext} Carousel`}
      ></SidePaginationButton>
    </div>
  );

  // ------------------------------------
  // Render
  // ------------------------------------

  return (
    <section
      id={carouselId}
      className={[
        styles['fern-paginated-carousel'],
        'mouseDraggable',
        className,
      ].join(' ')}
      aria-roledescription="carousel"
      aria-label={ariaLabel}
      ref={carouselRef}
      onKeyDown={handleArrowKeys}
    >
      {showPaginationArrows() && leftButton}
      <div
        className={[
          styles['fern-paginated-carousel--wrapper'],
          getDesktopPageClass(),
        ].join(' ')}
        ref={wrapperRef}
        style={{
          transform: transformValue,
        }}
      >
        {React.Children.map(children as any, (child: React.ReactElement, i) => {
          return React.cloneElement(child, {
            isSlideVisible: isSlideVisible(i),
            ariaLabel: `${i + 1} of ${numberOfSlides}`,
            segmentEventContext: segmentEventContext,
            segmentData: segmentData,
          });
        })}
      </div>
      {showPaginationArrows() && rightButton}
    </section>
  );
};

PaginatedCarousel.defaultProps = {
  desktopSlidesPerPage: 4,
  centerNavToSquareTileImages: false,
};

export const Slide: FunctionComponent<{
  children: JSX.Element;
  isSlideVisible?: boolean;
  ariaLabel?: string;
  segmentEventContext?: string;
  segmentData?: any;
  className?: string;
}> = ({ children, isSlideVisible, ariaLabel, className }) => {
  return (
    <div
      className={[className, styles['fern-paginated-carousel--slide']].join(
        ' '
      )}
      role="group"
      aria-roledescription="slide"
      aria-label={ariaLabel}
    >
      {React.cloneElement(children, {
        tabIndex: isSlideVisible ? null : -1,
      })}
    </div>
  );
};

function calculateNavContainerHeight(params: {
  element: HTMLElement;
  desktopSlidesPerPage: number;
  centerNavToSquareTileImages: boolean;
}): string {
  const { desktopSlidesPerPage, element, centerNavToSquareTileImages } = params;
  const tileSpacing = 30;

  const leftAndRightMargin = tileSpacing;
  const totalSpaceBetweenTiles = (desktopSlidesPerPage - 1) * tileSpacing;
  const tileWidth =
    (element.clientWidth - leftAndRightMargin - totalSpaceBetweenTiles) /
    desktopSlidesPerPage;

  // right now we're assuming that we're only centering nav bars to 1x1 images
  if (centerNavToSquareTileImages) {
    return `${tileWidth}px`;
  } else {
    return `100%`;
  }
}
