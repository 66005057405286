import { ErrorBoundary } from '@sentry/nextjs';
// import { CompanyReviewsCarousel } from '@shared_components/company_reviews_carousel/company_reviews_carousel';
// import { LoadOnScroll } from '@shared_components/load_on_scroll/load_on_scroll';
import React, { FunctionComponent } from 'react';
// import { EmailCaptureModal } from './email_capture_modal';
// import { FaqWidget } from './faq_widget';
// import { InspirationCornerWidget } from './inspiration_corner_widget/inspiration_corner_widget';
// import { InRealLifeWidget } from './in_real_life_widget';
import { ReferralCodeModal } from './referral_code_modal/referral_code_modal';
import { ShopByRoomWidget, ShopByRoomWidgetProps } from './shop_by_room_widget';
// import { ShopWhatsNewWidget } from './shop_whats_new_widget';
import bedsImg from '@components/homepage/shop_by_room_widget_images/popular-beds.jpg';
import desksImg from '@components/homepage/shop_by_room_widget_images/popular-desks.jpg';
import diningTablesImg from '@components/homepage/shop_by_room_widget_images/popular-dining.jpg';
import seatingImg from '@components/homepage/shop_by_room_widget_images/popular-seating.jpg';
import shopByRoomBedroomImg from '@components/homepage/shop_by_room_widget_images/shop-by-room-bedroom.jpg';
import shopByRoomDiningImg from '@components/homepage/shop_by_room_widget_images/shop-by-room-dining.jpg';
import shopByRoomLivingImg from '@components/homepage/shop_by_room_widget_images/shop-by-room-living.jpg';
import shopByRoomOfficeImg from '@components/homepage/shop_by_room_widget_images/shop-by-room-office.jpg';
// import { ValuePropWidget } from './value_props/value_prop_widget';

const shopByRoomData: ShopByRoomWidgetProps = {
  title: 'Shop by room',
  segmentEventContext: 'Homepage',
  data: [
    {
      href: 'n/room_types/living-room',
      image: shopByRoomLivingImg,
      name: 'Living Room',
    },
    {
      href: 'n/room_types/dining-room',
      image: shopByRoomDiningImg,
      name: 'Dining Room',
    },
    {
      href: 'n/room_types/bedroom',
      image: shopByRoomBedroomImg,
      name: 'Bedroom',
    },
    {
      href: 'n/room_types/offices',
      image: shopByRoomOfficeImg,
      name: 'Office',
    },
  ],
};

const mostPopularItemsData: ShopByRoomWidgetProps = {
  title: 'Shop most popular',
  segmentEventContext: 'Most Popular Items',
  data: [
    {
      href: 'n/item_types/bedframes/items',
      image: bedsImg,
      name: 'Beds',
    },
    {
      href: 'n/item_types/dining-tables/items',
      image: diningTablesImg,
      name: 'Dining Tables',
    },
    {
      href: 'n/item_types/sofas-sectionals/items',
      image: seatingImg,
      name: 'Seating',
    },
    {
      href: 'n/item_types/desks/items',
      image: desksImg,
      name: 'Desks',
    },
  ],
};

export interface LazyLoadedWidgetsProps {}
export const LazyLoadedWidgets: FunctionComponent<
  LazyLoadedWidgetsProps
> = () => {
  return (
    <>
      <ShopByRoomWidget {...shopByRoomData} />
      <ShopByRoomWidget {...mostPopularItemsData} />
      {/* <ValuePropWidget /> */}
      {/* <LoadOnScroll>
        <CompanyReviewsCarousel segmentEventContext="Homepage" />
        <InspirationCornerWidget slug="homepage" />
        <InRealLifeWidget />
        <FaqWidget />
        <ShopWhatsNewWidget />
        <EmailCaptureModal />
      </LoadOnScroll> */}
      {/* Modals */}
      <ErrorBoundary>
        <ReferralCodeModal />
      </ErrorBoundary>
    </>
  );
};
