import { CaretLeft } from '@shared_components/icons/caret-left';
import { CaretRight } from '@shared_components/icons/caret-right';
import React, { FunctionComponent } from 'react';
import { SegmentService } from '../../services/segment_service';
import styles from './side_pagination_button.module.css';

export const SidePaginationButton: FunctionComponent<{
  direction: 'Left' | 'Right';
  onClick: () => void;
  'aria-controls': string;
  segmentEventContext: string;
  disabled?: boolean;
}> = ({
  direction,
  onClick,
  disabled,
  'aria-controls': ariaControls,
  segmentEventContext,
}) => {
  function handleClick() {
    SegmentService.trackEngagementEvent(
      `${segmentEventContext} ${direction} Button Clicked`,
      {}
    );
    onClick();
  }

  const title = direction === 'Left' ? 'Previous' : 'Next';
  const icon = direction === 'Left' ? <CaretLeft /> : <CaretRight />;

  return (
    <button
      className={styles['side-pagination-button']}
      onClick={handleClick}
      disabled={disabled}
      title={title}
      aria-controls={ariaControls}
    >
      {icon}
    </button>
  );
};
