import { DeprecatedRoomTypesService } from '@services/deprecated_room_types_service';
import { FunctionComponent } from 'react';
import { DesignInspirationLinks } from '../design_inspiration_link/design_inspiration_link';
import { EasyPackageHardcodedLink } from '../easy_package_hardcoded_link';
import { ItemTypeLink } from '../item_type_link';
import { RoomTypeTagLink } from '../room_type_tag_link';
import { NavLinkWithRightArrow } from '../shop_all_item_types_link';
import styles from './nav-submenu-the-goods.module.css';

export const MobileItemTypes: FunctionComponent<{}> = () => {
  const { livingRoom, diningRoom, bedroom, office, moreItemTypes } =
    DeprecatedRoomTypesService.hardcodedTags;

  return (
    <>
      <div className={styles['fern-nav--item-type-links-grid']}>
        <div>
          {/* left col */}
          <RoomTypeTagLink roomTypeTag={livingRoom}></RoomTypeTagLink>
          {livingRoom.itemTypes.map((itemType, i) => (
            <ItemTypeLink itemType={itemType} key={i}></ItemTypeLink>
          ))}

          <div className={styles['nav-dropdown--header-link']}>
            <RoomTypeTagLink roomTypeTag={diningRoom}></RoomTypeTagLink>
          </div>
          {diningRoom.itemTypes.map((itemType, i) => (
            <ItemTypeLink itemType={itemType} key={i}></ItemTypeLink>
          ))}
        </div>

        <div>
          {/* right col */}
          <RoomTypeTagLink roomTypeTag={bedroom}></RoomTypeTagLink>
          {bedroom.itemTypes.map((itemType, i) => (
            <ItemTypeLink itemType={itemType} key={i}></ItemTypeLink>
          ))}

          <div className={styles['nav-dropdown--header-link']}>
            <RoomTypeTagLink roomTypeTag={office}></RoomTypeTagLink>
          </div>
          {office.itemTypes.map((itemType, i) => (
            <ItemTypeLink itemType={itemType} key={i}></ItemTypeLink>
          ))}

          <div className="fern-small-label mt-[54px] small-desktop:mt-[50px]">
            More
          </div>
          {moreItemTypes.map((itemType, i) => (
            <ItemTypeLink itemType={itemType} key={i}></ItemTypeLink>
          ))}
          <EasyPackageHardcodedLink />
        </div>
      </div>
      <div className="mt-30 mb-45">
        <div className="space-y-30">
          <NavLinkWithRightArrow
            text="Shop all items"
            href="/n/room_types"
            segmentAction="NavBar ViewAllLinkClicked"
            externalLink={false}
          />
          {/* <NavLinkWithRightArrow
            text="Take Our Virtual Design Quiz"
            href="/virtual-design-quiz"
            segmentAction="NavBar ViewAllLinkClicked"
            externalLink={true}
          /> */}
        </div>
      </div>
      {/* <div className="mb-45">
        <DesignInspirationLinks></DesignInspirationLinks>
      </div> */}
    </>
  );
};
