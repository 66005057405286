import { useEffect, useRef } from 'react';

/**
 * example use:
 * const ref = useMouseDraggableEffect(true)
 * @param carousel: HTMLElement
 */
export function useMouseDraggableEffect(mobileOnly: boolean) {
  const carouselRef = useRef<HTMLDivElement>(null);

  // This effect allows carousels to be draggable with the mouse when the browser window is either mobile or tablet width.
  // Actual mobile and tablet devices don't need this because the user can scroll with touch events
  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) {
      return;
    }

    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    function onMouseDown(e: MouseEvent) {
      if (!carousel) return;
      isDown = true;
      startX = e.pageX - carousel.offsetLeft;
      scrollLeft = carousel.scrollLeft;
    }

    function onMouseLeave(e: MouseEvent) {
      if (!carousel) return;
      isDown = false;
    }

    function onMouseUp(e: MouseEvent) {
      if (!carousel) return;
      carousel.classList.remove('dragging');
      isDown = false;
    }

    function onMouseMove(e: MouseEvent) {
      if (!carousel) return;
      const x = e.pageX - carousel.offsetLeft;
      const walk = x - startX;
      const isMobileOrTablet = window.innerWidth < 992;
      if (isDown) {
        carousel.classList.add('dragging');
        if (mobileOnly && isMobileOrTablet) {
          e.preventDefault();
          carousel.scrollLeft = scrollLeft - walk;
        } else if (!mobileOnly) {
          e.preventDefault();
          carousel.scrollLeft = scrollLeft - walk;
        }
      }
    }

    carousel.addEventListener('mousedown', onMouseDown);
    carousel.addEventListener('mouseleave', onMouseLeave);
    carousel.addEventListener('mouseup', onMouseUp);
    carousel.addEventListener('mousemove', onMouseMove);

    function cleanup() {
      if (!carousel) return;
      carousel.removeEventListener('mousedown', onMouseDown);
      carousel.removeEventListener('mouseleave', onMouseLeave);
      carousel.removeEventListener('mouseup', onMouseUp);
      carousel.removeEventListener('mousemove', onMouseMove);
    }

    return cleanup;
  });

  return carouselRef;
}
