import React, { FunctionComponent } from 'react';
import { BASE_CLASSES, IconProps } from './common_icon_props';

export const CaretLeft: FunctionComponent<IconProps> = ({
  className = '',
  style = {},
}) => {
  return (
    <svg
      className={`${BASE_CLASSES} ${className}`}
      style={style}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2871 0.226531C8.998 -0.070103 8.52316 -0.0762068 8.22653 0.212897L0.236617 8L8.22653 15.7871C8.52316 16.0762 8.998 16.0701 9.2871 15.7735C9.57621 15.4768 9.5701 15.002 9.27347 14.7129L2.38574 8L9.27347 1.2871C9.5701 0.997999 9.57621 0.523165 9.2871 0.226531Z"
      />
    </svg>
  );
};
