import { useModal } from '@custom_hooks/use_modal';
import { SegmentService } from '@services/segment_service';
import { SentryService } from '@services/sentry_service';
import {
  SaveReferralCodeResponse,
  SessionService,
} from '@services/session_service';
import { FernModal } from '@shared_components/fern-modal/fern_modal';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect, useRef, useState } from 'react';

export const ReferralCodeModal: FunctionComponent<{}> = () => {
  // ----------------------------------------
  // hooks and state
  // ----------------------------------------
  const router = useRouter();
  const modal = useModal({ segmentEventContext: 'Referral Code Modal' });
  const ref = useRef<HTMLTextAreaElement>(null);
  const [validReferralCode, setValidReferralCode] =
    useState<SaveReferralCodeResponse | null>();

  // ----------------------------------------
  // check promo code from query params
  // ----------------------------------------
  useEffect(() => {
    const { query } = router;
    const referralCode = query.referral_code;
    if (referralCode && typeof referralCode === 'string') {
      SessionService.saveReferralCode(referralCode)
        .then((res) => {
          setValidReferralCode(res);
          modal.openModal();
        })
        .catch(
          SentryService.catchPromise('Referral Code Modal > Save Referral Code')
        );
    }
    // Purposely not adding modal as a dependency here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  // ----------------------------------------
  // helper functions
  // ----------------------------------------
  function copyPromoCode() {
    ref.current?.select();
    document.execCommand('copy');
    SegmentService.trackEngagementEvent('Referral Code Modal Code Copied', {
      'referral-code': code,
    });
  }

  // ----------------------------------------
  // render default
  // ----------------------------------------
  if (!validReferralCode) {
    return <></>;
  }

  // ----------------------------------------
  // render if valid referral code
  // ----------------------------------------
  const { dollarsOff, duration, referrerGivenName, referrerFamilyName, code } =
    validReferralCode;

  const titleText = `You get $${
    (dollarsOff || 0) * duration
  } thanks to ${referrerGivenName}!`;

  const paymentsText =
    duration === 1
      ? 'your first payment'
      : `each of your first ${duration} payments`;

  return (
    <>
      <FernModal
        show={modal.open}
        onClose={modal.closeModal}
        segmentEventContext={modal.segmentEventContext}
        primaryAction={{ text: 'Start shopping', action: modal.closeModal }}
        ariaLabel={titleText}
        headerText=""
        cancelText=""
      >
        <div className="fern-large-header mb-20 mr-20 tablet:mr-0">
          {titleText}
        </div>
        <div>
          Use {referrerGivenName} {referrerFamilyName}&apos;s promo code{' '}
          <button onClick={copyPromoCode} className="fern-body-bold">
            {code}
          </button>{' '}
          during checkout and get up to ${dollarsOff} off {paymentsText}.
        </div>

        {/* this is "invisible" and only here for copy */}
        {/* TODO - consider installing clipboard.js */}
        <textarea
          onClick={copyPromoCode}
          ref={ref}
          className="absolute left-[99999px]"
          readOnly
          value={code}
        />
      </FernModal>
    </>
  );
};
