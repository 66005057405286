import React, { FunctionComponent } from 'react';
import {
  PaginatedCarousel,
  Slide,
} from '@shared_components/paginated_carousel/paginated_carousel';
import { FernStaticImage } from '@shared_components/fern_image/fern-image';

export type ShopByRoomWidgetProps = {
  title: string;
  data: { href: string; image: StaticImageData; name: string }[];
  segmentEventContext: string;
  segmentData?: any;
};

export const ShopByRoomWidget: FunctionComponent<ShopByRoomWidgetProps> = ({
  title,
  data,
  segmentEventContext,
  segmentData,
}) => {
  return (
    <div className="fern-grid--content mb-60 small-desktop:mb-90">
      <div className="fern-grid--fullwidth">
        <div className="mb-30 text-left fern-medium-header">{title}</div>
        <PaginatedCarousel
          segmentEventContext={`${segmentEventContext} Clicked`}
          segmentData={segmentData}
          centerNavToSquareTileImages
          ariaLabel={segmentEventContext}
        >
          {data.map((slide, i) => (
            <Slide key={i}>
              <a href={slide.href}>
                <FernStaticImage
                  imageUrl={slide.image}
                  altText={slide.name}
                  className="fern-image--1x1"
                />
                <div className="fern-large-body mt-15 text-left">
                  {slide.name}
                </div>
              </a>
            </Slide>
          ))}
        </PaginatedCarousel>
      </div>
    </div>
  );
};
